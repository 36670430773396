import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import numeral from 'numeral'
import Typography from '@mui/material/Typography'
import moment from 'moment-timezone'
import Stack from '@mui/material/Stack'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import { IQuoteCardsProps } from './types'
import { calculateDateToRender } from '../../utils/date'
import { theme } from '../../styles/mui-theme'

const QuoteCard: React.FC<IQuoteCardsProps> = ({
  quotes,
  appointmentId,
  portTimeZone,
  openEditQuote,
}) =>
  quotes.length === 0 ? (
    <Alert severity="info">No quotes for this appointment</Alert>
  ) : (
    <Paper elevation={3} style={{ marginBottom: 2, maxHeight: 240, overflow: 'auto' }}>
      {quotes.map((quote) => (
        <Card
          key={quote.quote_id}
          sx={{
            mb: 2,
            ml: 1,
            mr: 1,
            mt: 1,
            backgroundColor:
              appointmentId === quote.appointment_id
                ? theme.palette.custom.backgroundGrey
                : theme.palette.custom.backgroundYellow,
          }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center" alignItems="center" height={1}>
                  <Typography>{quote.quote_status}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={9} pl={0}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography>
                      <Typography component="strong" fontWeight="bold" fontSize={14}>
                        Product:
                      </Typography>{' '}
                      {quote.destination?.name}
                    </Typography>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Agent:
                      </Typography>{' '}
                      {`${quote.agent?.first_name} ${quote.agent?.last_name}`}
                    </Typography>

                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Last update:
                      </Typography>{' '}
                      {calculateDateToRender(portTimeZone, quote.quote_status_date!)}
                    </Typography>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Non-refundable:
                      </Typography>{' '}
                      {quote.quote_non_refundable ? 'Yes' : 'No'}
                    </Typography>
                    {quote.quote_comment && (
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Comment:
                        </Typography>{' '}
                        {quote.quote_comment}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Vessel:
                      </Typography>{' '}
                      {quote.ship.name}
                    </Typography>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Sail Date:
                      </Typography>{' '}
                      {quote.quoted_sail_date
                        ? moment.utc(quote.quoted_sail_date).format('MMM Do, YYYY')
                        : 'N/A'}
                    </Typography>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Amount:
                      </Typography>{' '}
                      {quote.quoted_amount
                        ? `$ ${numeral(quote.quoted_amount).format('0,0.00')}`
                        : 'N/A'}
                    </Typography>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Category:
                      </Typography>{' '}
                      {quote.quoted_stateroom_category}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <Stack direction="row" justifyContent="center" alignItems="center" height={1}>
                  <IconButton onClick={() => openEditQuote(quote)}>
                    <EditIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Paper>
  )
export default QuoteCard
