import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import { useState, FC, useEffect } from 'react'
import { IBookingFormProps, IBookingGroupItem, IBookingValues } from './types'
import { validationSchema } from './validations'
import { theme } from '../../styles/mui-theme'
import {
  bookingOriginsData,
  reasonsWhyData,
  savingsTypeOptions,
  stateroomOptions,
} from '../../utils/constants'

export const numberList = new Array(100).fill(0)

const BookingForm: FC<IBookingFormProps> = ({
  isOpen,
  onClose,
  products,
  vessels,
  onAccept,
  booking: bookingToEdit,
}) => {
  const [isSaveAndNew, setIsSaveAndNew] = useState<boolean>(false)
  const [openCardForm, setOpenCardForm] = useState<boolean>(false)
  const [bookingsGroup, setBookingsGroup] = useState<IBookingGroupItem[]>([])
  const [defaultValues] = useState(
    bookingToEdit
      ? {
          ...bookingToEdit,
          amount: Number(bookingToEdit.amount || 0) ? bookingToEdit.amount : '',
        }
      : {
          amount: '',
          bookingNumber: '',
          bookingOrigin: '',
          casinoBooking: false,
          comment: '',
          enableGroup: false,
          groupShellNumber: '',
          usedRCVisaCard: false,
          nonRefutable: false,
          numberOfBookings: '',
          pendingItems: false,
          productId: '',
          reasonWhy: '',
          stateRoomCategory: '',
          status: '',
          travelAgencyChange: false,
          vesselId: '',
        },
  )
  const minDate = moment()
  const [selectDate, setSelectDate] = useState<Moment | null>(
    bookingToEdit?.sailDate ? moment.utc(bookingToEdit.sailDate) : null,
  )

  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
  } = useForm<IBookingValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  })

  const bookingsOrigin = bookingOriginsData.map((item) => ({ value: item, label: item }))
  const stateroomCategory = stateroomOptions.map((item) => ({ value: item, label: item }))
  const savingsType = savingsTypeOptions.map((item) => ({ value: item, label: item }))
  const reasonsWhy = reasonsWhyData.map((item) => ({ value: item, label: item }))
  const bookingOrigin = watch('bookingOrigin')

  const onSetErrorsIfExist = (groupItems?: IBookingGroupItem[]) => {
    const bookingNumberList: IBookingGroupItem[] = (groupItems || bookingsGroup).map((item) => {
      const isValidNumber = +item.bookingNumber <= 9999999
      let error = item.bookingNumber ? '' : 'Booking Number is required'
      if (!isValidNumber) error = 'Booking number should be less or equal than 7 digits'

      return { ...item, error }
    })
    setBookingsGroup(bookingNumberList)
  }

  const onHandleSubmit = (values: IBookingValues) => {
    if (isSaveAndNew) reset()
    setSelectDate(null)
    onAccept(values, isSaveAndNew, openCardForm)
  }

  useEffect(() => {
    if (bookingOrigin !== 'Booking Form') {
      setValue('reasonWhy', '')
    }
  }, [bookingOrigin])

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <Box component="form" onSubmit={handleSubmit(onHandleSubmit)} noValidate>
        <DialogContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>{`${bookingToEdit ? 'Edit' : 'Add a '} Booking`}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ margin: '1rem 0' }} />
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="productId"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        select
                        id="productId"
                        label="Product"
                        type="text"
                        error={!!errors.productId}
                        helperText={errors?.productId?.message as string}>
                        {products.map((product) => (
                          <MenuItem key={`product-key-${product.id}`} value={product.id}>
                            {product.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="vesselId"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        select
                        id="vesselId"
                        label="Vessel"
                        type="text"
                        defaultValue=""
                        error={!!errors.vesselId}
                        helperText={errors?.vesselId?.message as string}>
                        {vessels.map((vessel) => (
                          <MenuItem key={`vessel-key-${vessel.id}`} value={vessel.id}>
                            {vessel.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        select
                        id="status"
                        label="Status"
                        type="text"
                        defaultValue=""
                        error={!!errors.status}
                        helperText={errors?.status?.message as string}>
                        <MenuItem key="Offered" value="offered">
                          Offered
                        </MenuItem>
                        <MenuItem key="Confirmed" value="confirmed">
                          Confirmed
                        </MenuItem>
                        <MenuItem key="Cancelled" value="cancelled">
                          Cancelled
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="bookingOrigin"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        select
                        id="BookingOrigin"
                        label="Booking Origin"
                        type="text"
                        defaultValue=""
                        // onChange={handleBookingOriginChange}
                        error={!!errors.bookingOrigin}
                        helperText={errors?.bookingOrigin?.message as string}>
                        {bookingsOrigin.map((booking) => (
                          <MenuItem
                            key={`booking-origin-key-${booking.value}`}
                            value={booking.value}>
                            {booking.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="sailDate"
                    render={({ field: { onChange, value, ...field } }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Sail Date"
                          minDate={minDate}
                          value={selectDate}
                          onChange={(newValue) => {
                            setSelectDate(newValue)
                            onChange(newValue?.format('YYYY-MM-DD'))
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              fullWidth
                              error={!!errors.sailDate}
                              sx={{ mt: 2 }}
                            />
                          )}
                          {...field}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                {bookingOrigin === 'Booking Form' && (
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="reasonWhy"
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          margin="normal"
                          fullWidth
                          select
                          id="reasonWhy"
                          label="Reason Why"
                          type="text"
                          defaultValue=""
                          error={!!errors.reasonWhy}
                          helperText={errors?.reasonWhy?.message as string}>
                          {reasonsWhy.map((reason) => (
                            <MenuItem
                              key={`booking-origin-key-${reason.value}`}
                              value={reason.value}>
                              {reason.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="bookingNumber"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        id="bookingNumber"
                        label="Booking Number"
                        type="number"
                        error={!!errors.bookingNumber}
                        helperText={errors?.bookingNumber?.message as string}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="stateRoomCategory"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        select
                        id="StateroomCategory"
                        label="Stateroom Category"
                        type="text"
                        defaultValue=""
                        error={!!errors.stateRoomCategory}
                        helperText={errors?.stateRoomCategory?.message as string}>
                        {stateroomCategory.map((stateroom) => (
                          <MenuItem
                            key={`booking-origin-key-${stateroom.value}`}
                            value={stateroom.value}>
                            {stateroom.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="amount"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        id="amount"
                        label="Amount"
                        type="number"
                        error={!!errors.amount}
                        helperText={errors?.amount?.message as string}
                      />
                    )}
                  />
                </Grid>
                {getValues('bookingOrigin') === 'Booking Form' && <Grid item xs={12} sm={6} />}
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="savingsType"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        select
                        id="savingsType"
                        label="Savings Type"
                        type="text"
                        defaultValue=""
                        error={!!errors.savingsType}
                        helperText={errors?.savingsType?.message as string}>
                        {savingsType.map((type) => (
                          <MenuItem key={`booking-origin-key-${type.value}`} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={0} sm={6} />
                <Grid item xs={12} sm={6}>
                  <Stack direction="column" columnGap={1}>
                    <Typography>Additional Information</Typography>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="nonRefutable"
                        render={({ field: { value, ...field } }) => (
                          <FormControlLabel
                            control={<Checkbox id="nonRefutable" checked={!!value} {...field} />}
                            label="Non-refundable"
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="pendingItems"
                        render={({ field: { value, ...field } }) => (
                          <FormControlLabel
                            control={<Checkbox id="pendingItems" checked={!!value} {...field} />}
                            label="Pending Items?"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="casinoBooking"
                        render={({ field: { value, ...field } }) => (
                          <FormControlLabel
                            control={<Checkbox id="casinoBooking" checked={!!value} {...field} />}
                            label="Casino Booking"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="travelAgencyChange"
                        render={({ field: { value, ...field } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox id="travelAgencyChange" checked={!!value} {...field} />
                            }
                            label="Travel Agency Change"
                          />
                        )}
                      />
                    </FormGroup>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack direction="column" columnGap={1}>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="usedRCVisaCard"
                        render={({ field: { value, ...field } }) => (
                          <FormControlLabel
                            control={<Checkbox id="usedRCVisaCard" checked={!!value} {...field} />}
                            label="Used Royal Caribbean Visa Card"
                          />
                        )}
                      />
                    </FormGroup>
                  </Stack>
                </Grid>
                {getValues('bookingOrigin') === 'The Retreat' && (
                  <Grid item xs={12} sm={6} alignSelf="flex-start">
                    <Controller
                      control={control}
                      name="bookingName"
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          margin="normal"
                          fullWidth
                          id="bookingName"
                          label="MCC Referrer"
                          type="text"
                          defaultValue=""
                          error={!!errors.bookingName}
                          helperText={errors?.bookingName?.message as string}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider sx={{ pb: 2 }} />
                  <Controller
                    control={control}
                    name="comment"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        multiline
                        id="comment"
                        label="Comment"
                        type="text"
                        rows={3}
                        error={!!errors.comment}
                        helperText={errors?.comment?.message as string}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>

        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            columnGap={1}
            sx={{ mb: '1.25rem', mr: 2 }}>
            {!bookingToEdit && (
              <Button
                sx={{
                  backgroundColor: theme.palette.custom.blue,
                  '&:hover': {
                    backgroundColor: theme.palette.custom.blue,
                  },
                }}
                variant="contained"
                type="submit"
                onClick={() => {
                  setOpenCardForm(true)
                  setIsSaveAndNew(false)
                  onSetErrorsIfExist()
                }}>
                Save and Add Credit Card
              </Button>
            )}
            {!bookingToEdit && (
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                onClick={() => {
                  setIsSaveAndNew(true)
                  onSetErrorsIfExist()
                }}>
                Save and Add New
              </Button>
            )}
            <Button
              color="success"
              variant="contained"
              type="submit"
              onClick={() => {
                setIsSaveAndNew(false)
                onSetErrorsIfExist()
              }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default BookingForm
