import { useState } from 'react'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import numeral from 'numeral'
import TableContainer from '@mui/material/TableContainer'
import { IAgentStatsTableProps, IExpandableRowProps } from './types'
import PortStatsByAgentTable from '../PortStatsByAgentTable'
import {
  headTableStyle,
  cellBorderRight,
  secondaryHeadTableStyle,
  secondaryTableRowStyle,
} from '../../../styles/generic-styles'
import { theme } from '../../../styles/mui-theme'
import { useAppSelector } from '../../../redux/hooks'
import { convertSecondsToMinutesSeconds } from '../../../utils/time'

const ExpandableRow: React.FC<IExpandableRowProps> = ({
  stat,
  isHighlighted = false,
  secondary,
}) => {
  const [open, setOpen] = useState(false)

  const cellStyles = isHighlighted
    ? {
        fontWeight: 'bold',
        background: theme.palette.divider,
      }
    : null

  const secondaryCellStyles = isHighlighted
    ? {
        fontWeight: 'bold',
        background: theme.palette.custom.gray,
      }
    : secondaryTableRowStyle

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={secondary ? secondaryCellStyles : cellStyles}>
          {stat.agentStats && stat.agentStats.length > 0 && (
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" sx={secondary ? secondaryCellStyles : cellStyles}>
          {stat.fullName}
        </TableCell>
        <TableCell
          align="center"
          sx={
            secondary
              ? { ...secondaryCellStyles, ...cellBorderRight }
              : { ...cellStyles, ...cellBorderRight }
          }>
          {stat.totalBookings || 'None'}
        </TableCell>
        <TableCell align="center" sx={secondary ? secondaryCellStyles : cellStyles}>
          {stat.inOfficeBookings || 'None'}
        </TableCell>
        <TableCell align="center" sx={secondary ? secondaryCellStyles : cellStyles}>
          {stat.bookingForms || 'None'}
        </TableCell>
        <TableCell
          align="center"
          sx={
            secondary
              ? { ...secondaryCellStyles, ...cellBorderRight }
              : { ...cellStyles, ...cellBorderRight }
          }>
          {stat.appBookings || 'None'}
        </TableCell>
        <TableCell
          align="center"
          sx={
            secondary
              ? { ...secondaryCellStyles, ...cellBorderRight }
              : { ...cellStyles, ...cellBorderRight }
          }>
          {stat.bookingsOffered || 'None'}
        </TableCell>
        <TableCell align="center" sx={secondary ? secondaryCellStyles : cellStyles}>
          {stat.inOfficeInteractions || 'None'}
        </TableCell>
        <TableCell
          align="center"
          sx={
            secondary
              ? { ...secondaryCellStyles, ...cellBorderRight }
              : { ...cellStyles, ...cellBorderRight }
          }>
          {stat.totalInteractions || 'None'}
        </TableCell>
        <TableCell
          align="center"
          sx={
            secondary
              ? { ...secondaryCellStyles, ...cellBorderRight }
              : { ...cellStyles, ...cellBorderRight }
          }>
          {numeral(stat.conversionByInteractions).format('0,0.00%')}
        </TableCell>
        <TableCell align="center" sx={secondary ? secondaryCellStyles : cellStyles}>
          {stat.avgAppointmentTime && stat.avgAppointmentTime > 0
            ? `${convertSecondsToMinutesSeconds(stat.avgAppointmentTime)} min`
            : 0}
        </TableCell>
      </TableRow>
      {stat.agentStats && stat.agentStats.length > 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <PortStatsByAgentTable stats={stat.agentStats} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const AgentStatsTable: React.FC<IAgentStatsTableProps> = ({ stats, secondary }) => {
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const currentAgent = stats.find((agent) => agent.userId === loggedUser?.userId)

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle} />
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle}>
              TEAM MEMBER
            </TableCell>
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle} align="center">
              TOTAL BOOKINGS
            </TableCell>
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle} align="center">
              IN-OFFICE BOOKINGS
            </TableCell>
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle} align="center">
              BOOKING FORMS
            </TableCell>
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle} align="center">
              APP BOOKINGS
            </TableCell>
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle} align="center">
              OFFERED BOOKINGS
            </TableCell>
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle} align="center">
              IN OFFICE INTERACTIONS
            </TableCell>
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle} align="center">
              TOTAL INTERACTIONS
            </TableCell>
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle} align="center">
              CONVERSIONS
            </TableCell>
            <TableCell sx={secondary ? secondaryHeadTableStyle : headTableStyle} align="center">
              AVG APPOINTMENT TIME
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loggedUser?.role === 'superadmin' || loggedUser?.role === 'admin' ? (
            stats.map((stat, index) => (
              <ExpandableRow
                key={`row-${index}`}
                stat={stat}
                secondary={secondary}
                isHighlighted={index === stats.length - 1}
              />
            ))
          ) : currentAgent ? (
            <ExpandableRow stat={currentAgent} secondary={secondary} />
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default AgentStatsTable
