import { useState } from 'react'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import numeral from 'numeral'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TableContainer from '@mui/material/TableContainer'
import moment from 'moment'
import { ICruiseStatsTableProps, IExpandableRowProps } from './types'
import { headTableStyle } from '../../../styles/generic-styles'
import { theme } from '../../../styles/mui-theme'
import { useAppSelector } from '../../../redux/hooks'
import AgentStatsTable from '../AgentStatsTable'

const ExpandableRow: React.FC<IExpandableRowProps> = ({ stat, isHighlighted = false }) => {
  const [open, setOpen] = useState(false)

  const cellStyles = isHighlighted
    ? {
        fontWeight: 'bold',
        background: theme.palette.divider,
      }
    : null

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={cellStyles}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" sx={cellStyles}>
          {moment.utc(stat.startDate).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell align="left" sx={cellStyles}>
          {moment.utc(stat.endDate).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell align="left" sx={cellStyles}>
          {stat.destination}
        </TableCell>
        <TableCell align="left" sx={cellStyles}>
          {stat.rdss}
        </TableCell>
        <TableCell align="left" sx={cellStyles}>
          {stat.startPort}
        </TableCell>
        <TableCell align="left" sx={cellStyles}>
          {stat.endPort}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.nights}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.stateroomForecast || 'None'}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.bookingGoalForecast ? numeral(Math.ceil(stat.bookingGoalForecast)).format('0') : 0}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.penetrationGoal ? numeral(Math.ceil(stat.penetrationGoal) / 100).format('0%') : 0}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.actualPenetration ? numeral(stat.actualPenetration).format('0,0.00%') : 0}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.totalBookings || 'None'}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.monthTracking ? numeral(stat.monthTracking).format('0,0.00%') : 0}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.charter ? 'Yes' : 'No'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {stat.agentStats && stat.agentStats.length > 1 ? (
                <AgentStatsTable stats={stat.agentStats} secondary />
              ) : (
                <div>No stats to display for this voyage</div>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const CruiseStatsTable: React.FC<ICruiseStatsTableProps> = ({ stats }) => {
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  let filteredStats = stats
  if (loggedUser?.role !== 'superadmin' && loggedUser?.role !== 'admin') {
    filteredStats = stats.filter((s) =>
      s.agentStats.find((agent) => agent.userId === loggedUser?.userId),
    )
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={headTableStyle} />
            <TableCell sx={headTableStyle}>Dept Date</TableCell>
            <TableCell sx={headTableStyle}>End Date</TableCell>
            <TableCell sx={headTableStyle}>Meta Product</TableCell>
            <TableCell sx={headTableStyle}>RDSS</TableCell>
            <TableCell sx={headTableStyle}>Embark</TableCell>
            <TableCell sx={headTableStyle}>Debark</TableCell>
            <TableCell sx={headTableStyle} align="center">
              Nights
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Stateroom Forecast
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Booking Goal Forecast
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Penetration Goal
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Actual Penetration
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Total Bookings
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Month Tracking %
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Charter
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredStats.map((stat, index) => (
            <ExpandableRow key={`row-${index}`} stat={stat} isHighlighted={false} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default CruiseStatsTable
