import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import { Controller, useForm } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import React, { useEffect, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import moment, { Moment } from 'moment'
import LoadingButton from '@mui/lab/LoadingButton'
import DialogActions from '@mui/material/DialogActions'
import { Card, CardContent } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { DropzoneArea } from 'mui-file-dropzone'
import b64ToBlob from 'b64-to-blob'
import fileSaver from 'file-saver'
import { useReactToPrint } from 'react-to-print'
import { IAppointmentQuoteProps } from './types'
import { validationSchema } from './validation'
import PrintableQuote from './PrintableQuote'
import { IQuotePayload } from '../../types/quotes'
import { IDestinationSimple } from '../../types/destinations'
import { IShipSimple } from '../../types/ships'
import { currencyOptions, stateroomOptions, reasonOptions } from '../../utils/constants'
import { customDropZone } from '../../styles/generic-styles'
import { getCurrencySymbolByName } from '../../utils/utils'

const QuoteForm: React.FC<IAppointmentQuoteProps> = ({
  isLoading,
  isLoadingDownloadAttachment,
  isOpen,
  onClose,
  onDownloadQuoteAttachment,
  onSubmit,
  onSubmitEditQuote,
  products,
  quote,
  room,
  vessels,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
    trigger,
  } = useForm<IQuotePayload>({
    defaultValues: {
      amount: quote?.amount ?? undefined,
      comment: quote?.comment ?? undefined,
      destinationId: quote?.destinationId ?? undefined,
      file: quote?.file ?? undefined,
      groupShellNumber: quote?.groupShellNumber ?? undefined,
      hasAttachment: quote?.hasAttachment ?? false,
      nonRefundable: quote?.nonRefundable ?? undefined,
      quoteId: quote?.quoteId ?? undefined,
      sailDate: quote?.sailDate ? moment.utc(quote.sailDate).format('YYYY-MM-DD') : undefined,
      shipId: quote?.shipId ?? undefined,
      stateroomCategory: quote?.stateroomCategory ?? undefined,
      noBookReason: quote?.noBookReason ?? undefined,
    },
    resolver: yupResolver(validationSchema),
  })
  const [numberOfQuotes, setNumberOfQuotes] = useState<number>(0)
  const [sailDate, setSailDate] = useState<Moment | null>(
    quote?.sailDate ? moment.utc(quote.sailDate) : null,
  )
  const [productValue, setProductValue] = useState<IDestinationSimple | null>(
    products.find((product) => product.id === quote?.destinationId) ?? null,
  )
  const [vesselValue, setVesselValue] = useState<IShipSimple | null>(
    vessels.find((vessel) => vessel.id === quote?.shipId) ?? null,
  )
  const [hasAttachment, setHasAttachment] = useState<boolean>(quote?.hasAttachment ?? false)

  const componentRef = useRef(null)
  const formData = {
    amount: watch('amount'),
    comment: watch('comment'),
    currency: getCurrencySymbolByName(watch('currency')),
    nonRefundable: watch('nonRefundable'),
    numberOfQuotes,
    exclusiveOnboardCredit: watch('exclusiveOnboardCredit'),
    promotion: watch('promotion'),
    product: productValue?.name,
    sailDate,
    stateroomCategory: watch('stateroomCategory'),
    noBookReason: watch('noBookReason'),
    vesselValue: vesselValue?.name,
  }
  const handlePrintToPDF = useReactToPrint({
    content: () => componentRef.current!,
    documentTitle: `${room ?? ''} ${formData.vesselValue} ${
      formData.sailDate ? formData.sailDate.format('MM-DD-YYYY') : ''
    } Quote`,
  })

  const handlePrint = async () => {
    const formIsValid = await trigger()
    if (formIsValid) {
      handlePrintToPDF()
    }
  }

  useEffect(() => {
    if (products) {
      setProductValue(products.find((product) => product.id === quote?.destinationId) ?? null)
    }
  }, [products])

  useEffect(() => {
    if (vessels) {
      setVesselValue(vessels.find((vessel) => vessel.id === quote?.shipId) ?? null)
    }
  }, [vessels])

  const removeAttachment = () => {
    setHasAttachment(false)
    setValue('hasAttachment', false)
  }

  const onClickDownloadAttachment = async () => {
    if (onDownloadQuoteAttachment) {
      const responseAttachment = await onDownloadQuoteAttachment({
        quoteId: quote?.quoteId,
        groupShellNumber: quote?.groupShellNumber,
      })

      if (responseAttachment?.success) {
        const blob = b64ToBlob(responseAttachment.zipAsBase64, 'application/zip')
        fileSaver.saveAs(blob, `attachment-quote-${quote?.quoteId}.zip`)
      }
    }
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit((data) => {
          if (!quote) {
            onSubmit(data)
          } else {
            onSubmitEditQuote!(data)
          }
        })}>
        <DialogContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>{quote ? 'Edit Quote' : 'Add a Quote'}</Typography>
            </Grid>
            <Grid item>
              <IconButton sx={{ padding: 0 }} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{ margin: '1rem 0' }} />
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="destinationId"
                    render={({ field: { onChange, ...field } }) => (
                      <Autocomplete
                        options={products}
                        getOptionLabel={(product) => product.name}
                        onChange={(_, data) => {
                          onChange(data?.id)
                          setProductValue(data)
                        }}
                        renderOption={(props, option) => (
                          <Box {...props} component="li" key={option.id} value={option.id}>
                            {option.name}
                          </Box>
                        )}
                        value={productValue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            label="Product"
                            error={!!errors.destinationId}
                            helperText={errors?.destinationId?.message as string}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="shipId"
                    render={({ field: { onChange, ...field } }) => (
                      <Autocomplete
                        options={vessels}
                        getOptionLabel={(vessel) => vessel.name}
                        onChange={(_, data) => {
                          onChange(data?.id)
                          setVesselValue(data)
                        }}
                        renderOption={(props, option) => (
                          <Box {...props} component="li" key={option.id} value={option.id}>
                            {option.name}
                          </Box>
                        )}
                        value={vesselValue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            label="Vessel"
                            fullWidth
                            error={!!errors.shipId}
                            helperText={errors?.shipId?.message as string}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item alignSelf="center">
                  <Controller
                    control={control}
                    name="sailDate"
                    render={({ field: { onChange, ...field } }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          {...field}
                          label="Sail Date"
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          value={sailDate}
                          minDate={moment()}
                          onChange={(newValue) => {
                            setSailDate(newValue)
                            onChange(newValue?.format('YYYY-MM-DD'))
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="amount"
                    defaultValue={0}
                    render={({ field }) => (
                      <TextField
                        label="Amount"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} alignSelf="center">
                  <Controller
                    control={control}
                    name="stateroomCategory"
                    render={({ field: { onChange, value, ...field } }) => (
                      <Autocomplete
                        options={stateroomOptions}
                        onChange={(_, data) => onChange(data)}
                        value={stateroomOptions.find((stateroom) => stateroom === value) ?? null}
                        renderOption={(props, option) => (
                          <Box {...props} component="li" key={option} value={option}>
                            {option}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} {...field} label="Stateroom Category" fullWidth />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="promotion"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField label="Promotion" type="text" fullWidth {...field} />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="exclusiveOnboardCredit"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        label="Exclusive Onboard Credit"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} alignSelf="center">
                  <Controller
                    control={control}
                    name="currency"
                    render={({ field: { onChange, value, ...field } }) => (
                      <Autocomplete
                        options={currencyOptions}
                        onChange={(_, data) => onChange(data)}
                        renderOption={(props, option) => (
                          <Box {...props} component="li" key={option} value={option}>
                            {option}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} {...field} label="Currency" fullWidth />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography>Additional information</Typography>
                    <Controller
                      control={control}
                      name="nonRefundable"
                      render={({ field: { value, ...field } }) => (
                        <FormControlLabel
                          control={<Checkbox checked={value ?? false} />}
                          label="Non-refundable"
                          {...field}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} alignSelf="center">
                  <Controller
                    control={control}
                    name="noBookReason"
                    render={({ field: { onChange, value, ...field } }) => (
                      <Autocomplete
                        options={reasonOptions}
                        onChange={(_, data) => onChange(data)}
                        value={reasonOptions.find((reason) => reason === value) ?? null}
                        renderOption={(props, option) => (
                          <Box {...props} component="li" key={option} value={option}>
                            {option}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            label="Why didn’t the guest book?"
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="comment"
                    render={({ field: { value, ...field } }) => (
                      <TextField
                        {...field}
                        value={value ?? ''}
                        fullWidth
                        multiline
                        maxRows={3}
                        minRows={3}
                        placeholder="Comment"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!hasAttachment ? (
                    <Controller
                      name="file"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        if (value && value.length > 0)
                          return (
                            <TextField
                              margin="normal"
                              fullWidth
                              label="Attachment"
                              type="text"
                              defaultValue={(value[0] as File).name}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => setValue('file', [])}>
                                      <HighlightOffIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )
                        return (
                          <Box sx={customDropZone}>
                            <Typography>Attachment</Typography>
                            <DropzoneArea
                              acceptedFiles={['image/jpeg,image/png,image/gif,image/jpg']}
                              dropzoneText="Drag and drop or click here to attach a file"
                              fileObjects={[]}
                              filesLimit={1}
                              initialFiles={value as (File | string)[]}
                              onChange={onChange}
                              showAlerts={false}
                            />
                          </Box>
                        )
                      }}
                    />
                  ) : (
                    <Box sx={customDropZone}>
                      <Typography>Attachment</Typography>
                      <LoadingButton
                        loading={isLoadingDownloadAttachment}
                        color="secondary"
                        onClick={onClickDownloadAttachment}>
                        Download Attachment
                      </LoadingButton>
                      <IconButton onClick={removeAttachment}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Box style={{ display: 'none' }}>
            <PrintableQuote ref={componentRef} formData={formData} />
          </Box>
          <Stack sx={{ mb: '1.25rem' }} direction="row" alignItems="center" columnGap={1}>
            <LoadingButton loading={isLoading} onClick={handlePrint} color="secondary">
              Print
            </LoadingButton>
            {!quote && (
              <LoadingButton
                loading={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleSubmit((data) => {
                  onSubmit(data, true)
                  reset()
                  setSailDate(null)
                  setNumberOfQuotes(0)
                })}>
                Save and Add New
              </LoadingButton>
            )}
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              color="success"
              sx={{ marginRight: 2 }}>
              Save
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default QuoteForm
