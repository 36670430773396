import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    custom: Palette['primary']
  }
  interface PaletteOptions {
    custom: PaletteOptions['primary']
  }

  interface PaletteColor {
    backgroundBlue?: string
    backgroundGrey?: string
    backgroundMediumGray?: string
    backgroundOrange?: string
    backgroundYellow?: string
    cyan?: string
    darkGray?: string
    gray?: string
    lightBlue?: string
    lightGrayRGB?: string
    yellow?: string
    purple?: string
    blue?: string
    orange?: string
    red?: string
  }
  interface SimplePaletteColorOptions {
    backgroundBlue?: string
    backgroundGrey?: string
    backgroundMediumGray?: string
    backgroundOrange?: string
    backgroundYellow?: string
    cyan?: string
    darkGray?: string
    gray?: string
    lightBlue?: string
    lightGrayRGB?: string
    yellow?: string
    purple?: string
    blue?: string
    orange?: string
    red?: string
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#002859',
    },
    secondary: {
      main: '#2977aa',
    },
    custom: {
      backgroundBlue: 'rgba(36, 136, 208, .50)',
      backgroundGrey: '#fbfbfb',
      backgroundMediumGray: '#0000001f',
      backgroundOrange: 'rgba(241, 110, 26, .50)',
      backgroundYellow: 'rgba(250, 186, 12, .50)',
      cyan: '#00b5ad',
      darkGray: '#444444',
      gray: '#908787',
      lightBlue: '#9BE5FC',
      lightGrayRGB: '170, 170, 170',
      main: '#171616',
      yellow: '#fbc21e',
      purple: '#c71efb',
      blue: '#0295fd',
      orange: '#e65100',
      red: '#d32f2f',
    },
  },
  typography: {
    fontFamily: [
      // eslint-disable-next-line spellcheck/spell-checker
      'Roboto',
      'Helvetica',
      'Arial',
      'MinionPro-Regular',
      'NeutraText-Bold',
      'NeutraText-Book',
    ].join(','),
  },
})

export default theme
