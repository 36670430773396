import moment from 'moment'
import numeral from 'numeral'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { IPortStatsByAgent, IPortStatsByAgentTableProps } from './types'
import {
  secondaryHeadTableStyle,
  secondaryTableRowStyle,
  cellBorderRight,
} from '../../../styles/generic-styles'
import { parseDateToString } from '../../../utils/date'
import { convertSecondsToMinutesSeconds } from '../../../utils/time'

const PortStatsByAgentTable: React.FC<IPortStatsByAgentTableProps> = ({ stats }) => {
  if (stats.length <= 0) return null
  return (
    <Paper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={secondaryHeadTableStyle}>Port / Date</TableCell>
            <TableCell sx={secondaryHeadTableStyle} align="center">
              Total Bookings
            </TableCell>
            <TableCell sx={secondaryHeadTableStyle} align="center">
              In-Office Bookings
            </TableCell>
            <TableCell sx={secondaryHeadTableStyle} align="center">
              Booking Forms
            </TableCell>
            <TableCell sx={secondaryHeadTableStyle} align="center">
              App Bookings
            </TableCell>
            <TableCell sx={secondaryHeadTableStyle} align="center">
              Offered Bookings
            </TableCell>
            <TableCell sx={secondaryHeadTableStyle} align="center">
              In-Office Interactions
            </TableCell>
            <TableCell sx={secondaryHeadTableStyle} align="center">
              Total Interactions
            </TableCell>
            <TableCell sx={secondaryHeadTableStyle} align="center">
              Conversion
            </TableCell>
            <TableCell sx={secondaryHeadTableStyle} align="center">
              Avg Appointment Time
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats.map((stat: IPortStatsByAgent, index) => (
            <TableRow key={`row-${index}`}>
              <TableCell sx={secondaryTableRowStyle}>
                {stat.port}
                <br />
                {moment.utc(parseDateToString(stat.date)).format('MM/DD/YYYY')}
              </TableCell>
              <TableCell sx={{ ...secondaryTableRowStyle, ...cellBorderRight }} align="center">
                {stat.totalBookings || 'None'}
              </TableCell>
              <TableCell sx={secondaryTableRowStyle} align="center">
                {stat.inOfficeBookings || 'None'}
              </TableCell>
              <TableCell sx={secondaryTableRowStyle} align="center">
                {stat.bookingForms || 'None'}
              </TableCell>
              <TableCell sx={{ ...secondaryTableRowStyle, ...cellBorderRight }} align="center">
                {stat.appBookings || 'None'}
              </TableCell>
              <TableCell sx={{ ...secondaryTableRowStyle, ...cellBorderRight }} align="center">
                {stat.bookingsOffered || 'None'}
              </TableCell>
              <TableCell sx={secondaryTableRowStyle} align="center">
                {stat.inOfficeInteractions || 'None'}
              </TableCell>
              <TableCell sx={{ ...secondaryTableRowStyle, ...cellBorderRight }} align="center">
                {stat.totalInteractions || 'None'}
              </TableCell>
              <TableCell sx={{ ...secondaryTableRowStyle, ...cellBorderRight }} align="center">
                {numeral(stat.conversionByInteractions).format('0,0.00%')}
              </TableCell>
              <TableCell sx={secondaryTableRowStyle} align="center">
                {stat.avgAppointmentTime && stat.avgAppointmentTime > 0
                  ? `${convertSecondsToMinutesSeconds(stat.avgAppointmentTime)} min`
                  : 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default PortStatsByAgentTable
